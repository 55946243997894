<template>
	<div class="editgiftdetails" v-loading="loading">
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px">
			<el-card class="box-card">
				<div slot="header" class="clearfix">
					<span>基本信息</span>
				</div>
				<div class="content">
					<el-form-item label="活动名称：" prop="Name">
						<el-input v-model="ruleForm.Name" :disabled='ruleForm.ActivityState==2' style="width: 220px;" placeholder="最多输入20个字" maxlength="20"></el-input>
					</el-form-item>

					<el-form-item label="活动时间：" required>
						<el-form-item prop="StartTime" class="el-form-date-pick">
							<el-date-picker v-model="ruleForm.StartTime" :disabled='Boolean(ruleForm.ActivityState)' format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"
							 type="datetime" placeholder="开始时间" :picker-options='maxOptions' >
							</el-date-picker>
						</el-form-item>
						<span style="margin:0 10px;">~</span>
						<el-form-item prop="EndTime" class="el-form-date-pick">
							<el-date-picker v-model="ruleForm.EndTime" :disabled='ruleForm.ActivityState==2' format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" type="datetime"
							 placeholder="结束时间" :picker-options='minOptions'>
							</el-date-picker>
						</el-form-item>
					</el-form-item>
				</div>
			</el-card>

			<el-card class="box-card" style="margin:15px 0 80px">
				<div slot="header" class="clearfix">
					<span>活动信息</span>
				</div>

				<div class="content" style="width:1050px;">
					<el-form-item label="参与活动群：" required  :class="{'is-error-button':!ruleForm.IsAllGroupJoin && !ruleForm.ActivityGroupShareJoinGroupList.length}">
            <div class="radio">
              <el-radio :disabled='ruleForm.ActivityState==2' v-model="ruleForm.IsAllGroupJoin" @change="changeRangeType" :label="true">全部参与</el-radio>
            </div>
            <div class="radio">
              <el-radio :disabled='ruleForm.ActivityState==2'  v-model="ruleForm.IsAllGroupJoin" @change="changeRangeType" :label="false">指定群参与</el-radio>
              <el-button type="text" v-show="!ruleForm.IsAllGroupJoin && ruleForm.ActivityState!= 2" @click="showGroupDia">选择群</el-button>
            </div>
						<!-- 参与群列表 -->
            <el-table  v-show="!ruleForm.IsAllGroupJoin" :data="paginationGroupList" style="width:1000px;margin-bottom:10px" 
              :row-key="groupRowKeys">
              <el-table-column :key="5" label="群名称" width="300px">
                <template slot-scope="scope">
                  <div class="dialog-name-content">
										<pre style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;">{{scope.row.Name}}</pre>
                  </div>
                </template>
              </el-table-column>
              <el-table-column :key="6" prop="GroupMasterNameShow" label="群主">
                <template slot-scope="scope">
									<span>{{scope.row.GroupMasterNameShow}}</span>
									<span v-if="scope.row.GroupMasterPhone">（{{scope.row.GroupMasterPhone}}）</span>
                </template>
							</el-table-column>
              <el-table-column :key="7" prop="GroupMemberCount" label="群总人数"></el-table-column>
              <el-table-column :key="8" label="操作">
                <template slot-scope="scope">
                  <el-button style="color:#F56C6C;" v-if="ruleForm.ActivityState!= 2"  @click="deleteGroup(scope.row,scope.$index)" type="text" size="small">删除</el-button>
									<span style="color:#C0C4CC;" v-else type="text" size="small">删除</span>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination v-if="groupPage.total" style="margin-top:20px;float:right;" @size-change="handleSizeChange"
              @current-change="handleCurrentChange" :current-page="groupPage.current" :page-sizes="[5,10, 20]" :page-size="groupPage.size"
              layout="total, sizes, prev, pager, next, jumper" :total="groupPage.total">
            </el-pagination>
					</el-form-item>


					<el-form-item label="赠送优惠券：" required :class="{'is-error-button':!ruleForm.ActivityGroupShareJoinCouponList.length}">
            <el-button type="text" @click="showCouponDia" v-if="ruleForm.ActivityState!= 2">选择优惠券</el-button>
						<div style="color: #999;">若优惠券设置领取次数限制，客户不满足领取条件时将不再发放；若优惠券剩余数量不足，将不再发放。请关注优惠券剩余数量并及时补充</div>
						<!-- 优惠券列表 -->
						<el-table :data="paginationCouponList" style="width:1000px;margin:15px 0" ref="multipleTable"
						 :row-key="rowKeys">
							<el-table-column :key="9" prop="CouponName" label="优惠券">
                <template slot-scope="scope">
                  <div style='width:150px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap'>{{scope.row.CouponName}}</div>
									<span style="color:#F56C6C;font-size:12px;" v-show="scope.row.IsFailure" >已失效</span>
                </template>
              </el-table-column>
              <el-table-column :key="10" prop="UseTypeValue" label="类型"></el-table-column>
              <el-table-column :key="11" prop="LeaveCount"  label="剩余数量"></el-table-column>
              <el-table-column :key="12" prop="CouponContent" label="优惠内容"></el-table-column>
              <el-table-column :key="13" label="操作">
                <template slot-scope="scope" >
									<el-button style="color:#F56C6C;" v-if="ruleForm.ActivityState!= 2" @click="deleteCoupon(scope.row,scope.$index)" type="text"
										size="small">删除</el-button>
									<span style="color:#C0C4CC;" v-else type="text" size="small">删除</span>
								</template>
              </el-table-column>
						</el-table>
						<el-pagination v-if="couponPage.total" style="margin-top:20px;float:right;" @size-change="handleCouponsSizeChange"
							@current-change="handleCouponsCurrentChange" :current-page="couponPage.current" :page-sizes="[5,10, 20]"
							:page-size="couponPage.size" layout="total, sizes, prev, pager, next, jumper" :total="couponPage.total">
						</el-pagination>

					</el-form-item>
				</div>

			</el-card>

		</el-form>

		<el-dialog title="选择群" :visible.sync="selectGroupVisible" width="1000px" class="dialog">
			<selectGroup
			 	:selectedData="seletedGroups" 
			 	v-if="selectGroupVisible"
			 	@getSelectList="getSelectGroupList" 
			></selectGroup>
		</el-dialog>

		<el-dialog title="选择优惠券" :visible.sync="selectCouponVisible" width="1000px" class="dialog data-dialog">
			<select-coupon 
				api="activitycouponpopupList" 
				:params="['KeyWord', 'UseType','','','IsUse']"
				:isUse='true'
			 	:selectedData="seletedCoupons" 
			 	@getSelectList="getSelectCouponList" 
			 	:visible.sync="selectCouponVisible"
			 	v-if="selectCouponVisible">
			</select-coupon>
		</el-dialog>

		<div class="bottom-save-btn">
			<el-button style="width:240px" @click="canselEdit">{{ruleForm.ActivityState== 2? '返回': '取消'}}</el-button>
			<el-button type="primary" style="width:240px" @click="submitForm('ruleForm')" v-if="ruleForm.ActivityState!= 2" :loading="saveLoading">保存</el-button>
		</div>

	</div>
</template>


<script>
	import {
		activityPayGiftSave,
		activityPayGiftInfo,
		groupaActivityDetail,
		groupaActivitySave
	} from '@/api/goods'

	import config from '@/config/index'
	import selectCoupon from '@/components/SelectMultipCoupon'
	import selectGroup from '@/components/SelectMultipGroup'
	export default {
		components: {
			selectCoupon,
			selectGroup
		},
		data() {
			var checkName = (rule, value, callback) => {
				if (!value || !value.trim()) {
					return callback(new Error('请输入活动名称'));
				} else {
					return callback()
				}
			};
			return {
				imgUrl: config.IMG_BASE,
        imgApi: config.UPLOAD_IMG,
        
				currentId: null,
				currentCopy: 0,
        
				loading: false,
				saveLoading: false,
				ruleForm: {
					ActivityState: null,
					Id: null,
					Name: '',
					StartTime: '',
					EndTime: '',
					IsAllGroupJoin: true,
					ActivityGroupShareJoinGroupList: [],
					GroupIdList: [],
					ActivityGroupShareJoinCouponList: [],
					CouponIdList: [],
        },
				rules: {
					Name: [{
						required: true,
						trigger: 'blur',
						validator: checkName
					}],
					StartTime: [{
						required: true,
						message: '请选择开始日期',
						trigger: 'blur'
					}],
					EndTime: [{
						required: true,
						message: '请选择结束日期',
						trigger: 'blur'
					}],
        },
				
				seletedGroups: [],
				selectGroupVisible: false,
				paginationGroupList: [],
				groupData: {},
				groupPage: {
					current: 1,
					total: 0,
					size: 5
        },

				seletedCoupons: [],
				selectCouponVisible: false,
				paginationCouponList: [],
				couponPage: {
					current: 1,
					total: 0,
					size: 5
        },
			}
		},
		computed: {
			minOptions: function() {
				let limitTime = this.ruleForm.StartTime
				let d = new Date();
				let day = d.getDate();
				let month = d.getMonth() + 1;
				let year = d.getFullYear();
				let currentDate = year + '-' + month + '-' + day + ' ' + '00:00:00'
				return {
					disabledDate(time) {
						if (limitTime) {
							return time < new Date(new Date(limitTime).toLocaleDateString())
						} else {
							return time < new Date(currentDate)
						}
					},
				}
			},
			//日期选择器限制选择
			maxOptions: function() {
				let limitTime = this.ruleForm.EndTime
				let d = new Date();
				let day = d.getDate();
				let month = d.getMonth() + 1;
				let year = d.getFullYear();
				let currentDate = year + '-' + month + '-' + day + ' ' + '00:00:00'
				return {
					disabledDate(time) {
						if (limitTime) {
							return (
								time > new Date(new Date(limitTime).toLocaleDateString()) ||
								time < new Date(currentDate)
							)
						} else {
							return time < new Date(currentDate)
						}
					}
				}
			}
		},
		created () {
			this.copy = this.$route.query.copy
			this.currentId = this.$route.query.id
			if(this.currentId) {
				this.getInformation()
			}
		},
		methods: {
			// 初始化数据
			async getInformation() {
				try {
					this.loading = true;
						let data = {
							Id: this.currentId
						}
						let result = await groupaActivityDetail(data)
						this.ruleForm = result.Result

						if (this.copy) {
							this.ruleForm.StartTime = ''
							this.ruleForm.EndTime = ''
							this.ruleForm.Id = null
							this.ruleForm.ActivityState = null
						}
						this.paginationCoupon()
						this.paginationGroup()
				} catch (e) {
					
				} finally {
					this.loading = false
				}

      },
      changeRangeType(e) {

      },
			rowKeys(row) {
				return row.Id
			},
			groupRowKeys(row) {
				return row.Id
      },
      
			//选择群
			showGroupDia() {
				this.seletedGroups = this.ruleForm.ActivityGroupShareJoinGroupList
				this.selectGroupVisible = true
			},
			//获取选择的群列表
			getSelectGroupList(data) {
				this.ruleForm.ActivityGroupShareJoinGroupList = data.map((t) => {
					return {
						...t,
						GroupMasterNameShow: t.GroupManagerName,
						GroupMasterPhone: t.GroupManagerPhone
					}
				})
				console.log("this.ruleForm123",data)
				this.selectGroupVisible = false
				this.groupPage.current = 1
				this.paginationGroup()
			},
			// 删除指定群
			deleteGroup(val, index) {
				this.ruleForm.ActivityGroupShareJoinGroupList = this.ruleForm.ActivityGroupShareJoinGroupList.filter(t => {
					if (t.Id != val.Id) {
						return t
					}
				})
				if((this.ruleForm.ActivityGroupShareJoinGroupList.length) % this.groupPage.size == 0 && this.groupPage.current > 1) {
					this.groupPage.current = this.groupPage.current - 1;
				}
				this.paginationGroup()

			},
			//群分页
			paginationGroup() {
				this.groupPage.total = this.ruleForm.ActivityGroupShareJoinGroupList.length;
				let start = (this.groupPage.current - 1) * this.groupPage.size
				let end = start + this.groupPage.size
				this.paginationGroupList = this.ruleForm.ActivityGroupShareJoinGroupList.slice(start, end);
				this.$forceUpdate()
			},

			handleSizeChange(val) {
				this.groupPage.size = val;
				this.paginationGroup()
			},
			handleCurrentChange(val) {
				this.groupPage.current = val;
				this.paginationGroup()
			},

			// 选择优惠券
			showCouponDia() {
				this.seletedCoupons = this.ruleForm.ActivityGroupShareJoinCouponList
				console.log("this.seletedCouponId",this.ruleForm.ActivityGroupShareJoinCouponList,this.seletedCoupons)
				this.selectCouponVisible = true
			},

			//获取选择的优惠券列表
			getSelectCouponList(data) {
				this.ruleForm.ActivityGroupShareJoinCouponList = data.slice(0)
				this.selectCouponVisible = false
				this.couponPage.current = 1
				this.paginationCoupon()
			},
			
			//coupon分页
			paginationCoupon() {
				this.couponPage.total = this.ruleForm.ActivityGroupShareJoinCouponList.length
				let start = (this.couponPage.current - 1) * this.couponPage.size
				let end = start + this.couponPage.size
				let pagination = this.ruleForm.ActivityGroupShareJoinCouponList.slice(start, end);
				this.paginationCouponList = pagination
				this.$forceUpdate()
			},
			// 删除优惠券
			deleteCoupon(val,index) {
				this.ruleForm.ActivityGroupShareJoinCouponList=this.ruleForm.ActivityGroupShareJoinCouponList.filter(t=>{
					if(t.Id!=val.Id){
						return t
					}
				})
				if ((this.ruleForm.ActivityGroupShareJoinCouponList.length) % this.couponPage.size == 0 &&
					this.couponPage.current > 1) {
					this.couponPage.current = this.couponPage.current - 1;
				}
				this.paginationCoupon()
			},
			handleCouponsSizeChange(val) {
				this.couponPage.size = val
				this.paginationCoupon()
			},
			handleCouponsCurrentChange(val) {
				this.couponPage.current = val
				this.paginationCoupon()
			},

			// 保存
			submitForm(formName) {
				this.$refs[formName].validate(async (valid) => {
					if (valid) {
						try {
							this.saveLoading = true
							if (!this.ruleForm.ActivityGroupShareJoinGroupList.length && !this.ruleForm.IsAllGroupJoin) {
								this.$common.completeFormInformation('请选择参与活动的群','is-error-button')
								return
							}
							if (!this.ruleForm.ActivityGroupShareJoinCouponList.length) {
								this.$common.completeFormInformation('请选择赠送优惠券','is-error-button')
								return
							}
							this.ruleForm.GroupIdList  = this.ruleForm.ActivityGroupShareJoinGroupList.map(item => {
								return item.Id
							})

							console.log("5",this.ruleForm)
							this.ruleForm.CouponIdList  = this.ruleForm.ActivityGroupShareJoinCouponList.map(item => {
								return item.Id
							})
							let result = await groupaActivitySave( this.ruleForm )
							if (result.IsSuccess) {
								this.$message({
									showClose: true,
									type: 'success',
									message: '操作成功!'
								});
								this.$router.push({
									path: '/market/groupShare/shareActivities'
								});
							}

						} catch (e) {
				
						} finally {
							this.saveLoading = false
						}
					} else {
						this.$common.completeFormInformation('请完善活动信息','is-error')
						return false;
					}
				});
			},
			// 取消
			canselEdit() {
				this.$router.push({
					path: '/market/groupShare/shareActivities'
				});
			},
		}
	}
</script>

<style lang="less" scoped>
	.editgiftdetails {
		padding: 10px;

		.el-form-date-pick {
			display: inline-block;
		}

		.towLines {
			word-break: break-all;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}

		.lookImgsWidth {
			width: 460px;
		}

		.dialog-name-content {
			width: 200px;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
		}

		.bottom-save-btn {
			position: fixed;
			width: 100%;
			background: #fff;
			bottom: 0;
			text-align: center;
			padding: 20px 0;
			z-index: 100;
			box-shadow: 13px 1px 6px #999;
		}

	}
</style>
