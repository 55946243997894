<template>
	<div class="contanier">
		<div class="filter-container title-border">
			<!-- 关键字搜索 -->
			<div class="filter-item">
				<label class="label">关键字: </label>
				<el-input v-model="keyWords" style="width: 200px;" placeholder="群名称"></el-input>
			</div>
			<div class="filter-item">
				<el-button type="primary" style="margin:0 30px;width:90px;" @click="handleFilter">查询</el-button>
			</div>
		</div>
		<!-- table -->
		<div class="table-container content">
			<el-table max-height="450" :data="tableDataList" v-loading="loading"  @sort-change="sortChange" style="width: 100%" ref="compSelectGroupTable" :row-key="getRowKeys"
			 @selection-change="handleSelectionChange">
				<el-table-column type="selection" width="55"  :reserve-selection="true"></el-table-column>
				<el-table-column prop="Name" label="群名称" width="200"> 
					<template slot-scope="scope">
            <div class="dialog-name-content">
							<pre style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;">{{scope.row.Name}}</pre>
            </div>
					</template>
        </el-table-column>
				<el-table-column label="群主" width="180">
					<template slot-scope="scope">
						<span>{{scope.row.GroupManagerName}}</span>
						<span v-if="scope.row.GroupManagerPhone">（{{scope.row.GroupManagerPhone}}）</span>
					</template>
				</el-table-column>
				<el-table-column prop="SellMoney" sortable>
          <template slot="header" slot-scope="scope">
            <span>群总业绩</span>
            <el-tooltip class="item" effect="dark" content="绑定群的成员，支付成功的订单数量" placement="top-start">
              <i class="el-icon-warning"></i>
            </el-tooltip>
          </template>
        </el-table-column>
				<el-table-column prop="SellOrderCount" sortable>
          <template slot="header" slot-scope="scope">
            <span>群总订单</span>
            <el-tooltip class="item" effect="dark" content="绑定群的成员，订单支付金额，包含运费" placement="top-start">
              <i class="el-icon-warning"></i>
            </el-tooltip>
          </template>
        </el-table-column>
				<el-table-column prop="GroupMemberCount" label="群总人数" sortable></el-table-column>
				<el-table-column prop="AddTime" label="创建时间"></el-table-column>
			</el-table>

			<el-checkbox style="margin-top:25px;margin-left:13px;float:left;" :value="checkedAll" @change="selectCurrentAll">当前页全选</el-checkbox>
			<el-pagination v-if="page.total" style="margin-top:20px;float:right;" @size-change="handleSizeChange"
			 @current-change="handleCurrentChange" :current-page="page.current" :page-sizes="[5,10, 15, 20, 25, 30]" :page-size="page.size"
			 layout="total, sizes, prev, pager, next, jumper" :total="page.total">
			</el-pagination>
		</div>
		<div slot="footer" class="dialog-footer">
			已选中
			<span style="color:#F56C6C">{{multipleSelection.length}}</span>
			件
			<el-button class="button" @click="saveSelectProList" style="margin-left:10px;" type="primary">确定</el-button>
		</div>
	</div>
</template>
<script>

	import config from '@/config/index'
	import {
		groupList,
	} from '@/api/goods'
	export default {
		name: 'selectGroup',
		props: {
			selectedData: { //需要选中的群列表
				type: Array,
				default:()=>{
					return []
				}
			},
		},
		data() {
			return {
				keyWords: '',
				
        OrderBy:'',
				IsAsc:'',
				
				page: {
					total: 0,
					current: 1,
					size: 10
				},

				tableDataList: [],
				selectCount: 0,
				multipleSelection: [],
				
				loading: false,
				isReady:false,
			}
		},
		created() {
			this.getList()
		},
		watch:{
		},
		computed:{
			checkedAll(){
				var val = this.multipleSelection;
				let ableSelectLength = 0 //获取当前页可选的全部页数
				var remarkLen = 0;
				var ids = val.map(item=>{
					return item.Id
				})
				this.tableDataList.forEach(item => {
					if (ids.indexOf(item.Id) > -1 || item.Remark) {
						ableSelectLength ++;
					}
					if (item.Remark){
						remarkLen ++;
					}
				});
				
				return (ableSelectLength > 0 && this.tableDataList.length == ableSelectLength && remarkLen!=ableSelectLength) ? true:false;
			}
		},
		mounted() {
			
			
		},
		methods: {
			async getList() {
				this.loading = true;
				try {
					let data = {
            Keywords: this.keyWords,
            OrderBy:this.OrderBy,
            IsAsc: this.IsAsc,
						Skip: (this.page.current - 1) * this.page.size,
						Take: this.page.size
					};
           
					let result = await groupList(data);
					this.page.total = result.Result.Total;
					this.tableDataList=result.Result.Results;

				} catch (error) {
					console.log(error);
				} finally {
					if (!this.isReady){
						this.selectedData.forEach(item => {
							this.$refs['compSelectGroupTable'].toggleRowSelection(item, true);
            })
						this.isReady = true;
					}
					this.loading = false;
				}
			},
			sortChange(column, prop, order) {
				if (column.order == "ascending") {
					this.IsAsc = true
					this.OrderBy = column.prop
				} else if(column.order == "descending") {
					this.IsAsc = false
					this.OrderBy = column.prop
				} else {
					this.IsAsc = ''
					this.OrderBy = ''
				}
				this.page.current = 1;
				this.getList()
			},
			//外部全选当前页
			selectCurrentAll(e) {
				this.$refs['compSelectGroupTable'].toggleAllSelection()
			},
			//当前页全选
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			// 切换显示条数
			handleSizeChange(val) {
				this.page.size = val;
				this.getList();
			},
			// 翻页
			handleCurrentChange(val) {
				this.page.current = val;
				this.getList()
			},
			clearSelection() {
				this.$nextTick(() => {
					this.$refs.compSelectGroupTable.clearSelection();
				});
			},
			getRowKeys(row) {
				return row.Id;
			},
			handleFilter() {
				this.page.current = 1;
				this.getList();
			},
			//关闭弹框，以及曝光选择的列表
			saveSelectProList() {
				if (!this.multipleSelection.length){
					this.$message({
						message: '请选择群',
						type: 'info'
					})
					return
				}
				this.$emit('getSelectList', this.multipleSelection)
			}

		}
	}
</script>
<style lang="less" scoped>
	.contanier {
		position: relative;
		overflow: hidden;
	}

	.content {
		overflow: hidden;
	}

	.dialog-footer {
		margin-top: 20px;
		text-align: center;

		.button {
			width: 150px;
		}
	}
	.dialog-name-content{
		width: 180px;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
  }
</style>
